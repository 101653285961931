<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name ? scope.row.name : scope.row.nickname }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="电话" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.l_mobile }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="附件" min-width="110" align="center">
				  <template slot-scope="scope">
					<div style="color: #2d8cf0;cursor: pointer;" @click="handleViewFile(scope.row.path)">查看</div>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		<div class="w-100 h-auto flex flex-column">
        
		</div>
		</el-card>
		
		<el-dialog :visible.sync="office" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<iframe :src="file_url" frameborder="0" width="100%" height="600"></iframe>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="officeCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="officeCancel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'activity_list',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				id:'',
				keyword: '',
			},
			id:'',
			office:false,
			file_url:"",
		}
	},
	mounted() {
		if(this.$route.query.id){
			 this.queryForm.id = this.$route.query.id
			 this.getList()
		}
	},
	methods:{
		cancel(){
			this.$router.go(-1);
		},
		officeCancel(){
			this.office = false
		},
		handleViewFile(url){
			this.office = true
			this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent('https://api.mem.ahpea.cn/'+url)
		},
	},
}
</script>

<style>
</style>